import React from "react";
import { connect, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Button from "../../components/Button";
import * as activitiesRedux from "../../modules/activities/redux/activitiesRedux";
import ActivityDrawerFilters from "../../modules/activities/ActivityDrawerFilters";
import { TooltipOverlay } from "../../components/TooltipOverlay";
import { Button as BootstrapButton } from "react-bootstrap";
import SearchInput from "../../app/partials/SearchInput";
import SortDropdown from "../../modules/activities/customDropdowns/SortDropdown";
import FilterByDropdown from "app/popleads/modules/activities/customDropdowns/FilterByDropdown";
import FilterByAssigneeDropdown from "app/popleads/modules/activities/customDropdowns/FilterByAssigneeDropdown";
import { saveUserPreference, silentlyParseJSON } from "app/popleads/app/functions/util";
import { getWindowSize } from "app/popleads/modules/activities/utils";
import { TABLET_BREAKPOINT, XXL_BREAKPOINT } from "app/popleads/app/contants";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";

const ActivitiesActions = (props) => {
    const userPreferences = silentlyParseJSON(props.user.activityPageConfig, "{}");
    const { width } = getWindowSize();
    const greaterThanTabletSize = width > TABLET_BREAKPOINT;
    const isMobile = width <= XXL_BREAKPOINT;
    const { isFromMobileApp } = useSelector((state) => state.app);
    return (
        <div className="d-flex flex-column-reverse w-100 flex-md-row justify-content-end">
            {props.viewType !== "mindmap" && (
                <>
                    <SearchInput
                        id="kt_datatable_search_query"
                        className="activity-search form-control"
                        placeholder="Search by Activity Name"
                        variant="disable-outline-input"
                        value={props.searchQuery}
                        onChange={(event) => props.setSearchQuery(event.target.value)}
                        minLength={2}
                        debounceTimeout={500}
                    />
                    {/* Spacer  */}
                    <div style={{ flex: "1 1 0%", placeSelf: "stretch" }} />
                </>
            )}
            <div className="d-flex mb-4 mb-md-0">
                {props.viewType !== "mindmap" && <SortDropdown orderBy={props.orderBy} setOrderBy={props.setOrderBy} />}
                <FilterByAssigneeDropdown />
                <FilterByDropdown />
                <ActivityDrawerFilters />
                {greaterThanTabletSize && (
                    <TooltipOverlay
                        placement="bottom"
                        tooltip={
                            props.selectedCampaignListFromFilter?.length !== 1
                                ? "Select only one campaign to enable Mindmap"
                                : "Mind Map"
                        }
                    >
                        <BootstrapButton
                            variant={props.viewType === "mindmap" ? "secondary" : "outline-secondary"}
                            className="ml-1 ml-sm-4 customBootstrapBtn"
                            onClick={() => props.setViewType("mindmap")}
                            disabled={props.selectedCampaignListFromFilter?.length !== 1}
                        >
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/mind-map.svg")} />
                        </BootstrapButton>
                    </TooltipOverlay>
                )}

                {/* <TooltipOverlay placement="top" tooltip={"Export as an excel file"}>
                        <Button
                            icon
                            outline
                            variant="success"
                            // onClick={}
                            className="excelIcon ml-4"
                            src="/media/svg/popleads/export_excel_icon.svg"
                        />
                    </TooltipOverlay> */}
                <div className="btn-group btn-group-toggle btn-group-subheader ml-2" data-toggle="buttons">
                    <label className={props.viewType === "board" ? "selectGridBtn active" : "selectGridBtn"}>
                        <input
                            type="radio"
                            name="viewType"
                            id="option1"
                            onChange={() => {
                                props.setViewType("board");
                                saveUserPreference("activity_page", { ...userPreferences, view: "board" });
                            }}
                            autoComplete="off"
                            checked={props.viewType === "board" ? true : false}
                        />
                        <TooltipOverlay placement="top" tooltip={"Board View"}>
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/select_grid_icon.svg")} />
                        </TooltipOverlay>
                    </label>
                    <label className={props.viewType === "table" ? "selectTableBtn active" : "selectTableBtn"}>
                        <input
                            type="radio"
                            name="viewType"
                            id="option2"
                            onChange={() => {
                                props.setViewType("table");
                                saveUserPreference("activity_page", { ...userPreferences, view: "table" });
                            }}
                            autoComplete="off"
                            checked={props.viewType === "table" ? true : false}
                        />
                        <TooltipOverlay placement="bottom" tooltip={"List View"}>
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/select_list_icon.svg")} />
                        </TooltipOverlay>
                    </label>
                </div>
                {/* FOR MOBILE + TABLET */}
                <Button
                    className="d-flex d-lg-none justify-content-center align-items-center ml-1"
                    variant="success"
                    icon
                    src="/media/svg/popleads/plus.svg"
                    outline
                    onClick={() => {
                        props.setShowAddActivityModal(true);
                        props.setIsSubActivity(false);
                    }}
                />
                {/* FOR LG SCREEN AND ABOVE */}
                <Button
                    className="d-none d-lg-flex ml-4"
                    title="New Activity"
                    variant="success"
                    icon
                    src="/media/svg/popleads/plus.svg"
                    outline
                    onClick={() => {
                        props.setShowAddActivityModal(true);
                        props.setIsSubActivity(false);
                    }}
                />
                {isMobile && !isFromMobileApp && <ChatGptDrawer />}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        viewType: state.activities.viewType,
        isSubActivity: state.activities.isSubActivity,
        selectedCampaignListFromFilter: state.activities.selectedCampaignListFromFilter,
        orderBy: state.activities.orderBy,
    };
};

const mapDispatchToProps = {
    ...activitiesRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesActions);
